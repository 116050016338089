import React, {useState, useEffect} from "react";
import {
    WppInput,
    WppCheckbox,
    WppSelect,
    WppButton,
    WppTypography,
    WppListItem,
    WppSpinner, WppTooltip, WppIconHelp, WppIconClock,

} from "@platform-ui-kit/components-library-react";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {AppConfig} from "../../../AppConfig";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {NetDagConstants} from "../../DataProcessing/NetVariableIngestion/NetDagConstants";
import Item from "antd/es/list/Item";

//Create function to get default value based on environment variables
const getDefaultValue = (defaultParamValue) => {
    // Check if defaultParamValue is null, undefined, or an empty string
    if (!defaultParamValue || typeof defaultParamValue !== 'string' || defaultParamValue.trim() === "") {
        return defaultParamValue;
    }
    if (typeof defaultParamValue === 'string' && defaultParamValue !== undefined && defaultParamValue.startsWith("env:")) {
       console.log(defaultParamValue.split(':')[1])
    //    console.log(AppConfig)
        console.log(NetDagConstants[defaultParamValue.split(':')[1]] );
        console.log(AppConfig[defaultParamValue.split(':')[1]])
       let value ="";
       if(NetDagConstants[defaultParamValue.split(':')[1]] == undefined && AppConfig[defaultParamValue.split(':')[1]] != undefined) {
            value =AppConfig[defaultParamValue.split(':')[1]].replace("https://console.cloud.google.com/storage/browser/", "gs://")
       }else if(NetDagConstants[defaultParamValue.split(':')[1]] != undefined){
            value = NetDagConstants[defaultParamValue.split(':')[1]].replace("https://console.cloud.google.com/storage/browser/", "gs://");
       }
        return value;
    }
    if (typeof defaultParamValue === 'string' && defaultParamValue !== undefined) {
        let value = defaultParamValue.replace("https://console.cloud.google.com/storage/browser/", "gs://")
        console.log("defaultParamValue: " + value);
        return value;
        // Return empty string for null or empty values
    }

    /* Check if defaultParamValue starts with "env:"
       Extract the key after "env:"
       Retrieve value from AppConfig using the key and replace the specified string */

    //TODO: Implemented shortcut to avoid defining multiple values, need to fix in future
    // return  defaultParamValue.startsWith("env:") ?
    //         AppConfig[defaultParamValue.split(':')[1]].replace("https://console.cloud.google.com/storage/browser/", "gs://")
    //         : defaultParamValue
    return "";
}

const convertToJSONArray = (inputString) => {
    // Check if input string is empty or null
    if (!inputString) {
        return [];
    } else {
        // Split the input string by comma and create a list
        return inputString.split(",");
    }
};

export const TriggerDagStep = (props: {
    onDagSuccess: () => void;
    configName: any;
    survey?: string;
}) => {
    const workflowConfig: any = props.configName;

    const [hasDagBeenTriggered, setHasDagBeenTriggered] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoadings, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [status, setStatus] = useState("-");
    const [formData, setFormData] = useState({});
    const [wave, setWave] = useState([]);
    const [market, setMarket] = useState([]);
    const [marketArray, setMarketArray] = useState([]);
    var allMarketCodes, uniqueMarketCodesArray;

    var year = [], waveLabel = [], wavesByYear = {}, marketCodes = [];
    var data;
    const storedData = localStorage.getItem('surveyWaveData');
    if (storedData) {
        data = JSON.parse(storedData);
    }
    if (data) {
        year = data.map(survey => ({
            name: survey.YEAR,
            value: survey.YEAR
        }));
        year = Array.from(new Set(year.map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item));
        wavesByYear = data.reduce((acc, survey) => {
            if (!acc[survey.YEAR]) {
                acc[survey.YEAR] = new Set();
            }
            acc[survey.YEAR].add(survey.WAVE);
            return acc;
        }, {});
        // Convert waves sets to arrays
        for (const year in wavesByYear) {
            wavesByYear[year] = Array.from(wavesByYear[year]).map(wave => ({
                name: wave,
                value: wave
            }));
        }

        waveLabel = data.map(survey => ({
            name: survey.WAVE,
            value: survey.WAVE
        }));
        waveLabel = Array.from(new Set(waveLabel.map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item));

        allMarketCodes = data.flatMap(item => item.MARKET_CODES.split(', ').map(code => code.trim()));
        marketCodes = Array.from(new Set(allMarketCodes));
        uniqueMarketCodesArray = marketCodes;
        marketCodes = marketCodes.map(code => ({
            name: code,
            value: code
        }))

    }
    const handleInputChange = (e) => {
        //console.log(e);
        const {id, value, type} = e.target;
        if (id == "year") {
            setWave(wavesByYear[value]);
            const allMarketCodes: string[] = data
                .filter(item => item.YEAR === value)
                .flatMap(item => item.MARKET_CODES.split(', ').map(code => code.trim()));
            var uniqueMarketCodes = Array.from(new Set(allMarketCodes));
            uniqueMarketCodesArray = uniqueMarketCodes;
            var codes = uniqueMarketCodes.map(code => ({
                name: code,
                value: code
            }))
            console.log(codes)
            setFormData((prevFormData) => ({...prevFormData, ["markets"]: ''}))
            setMarket(codes)
        }
        uniqueMarketCodesArray = market.map(item => item.name);       
        console.log(uniqueMarketCodesArray);
        // console.log("id: " + id + " value: " + value + " type: " + type);
        // console.log("State of checkbox -" + e.target.checked);
        var inputValue;
        if (id == "markets" && value.includes("clear_all")) {
            inputValue =
                "checked" in (e?.detail || {}) ? e.detail.checked : []; // Condition to handle if input type is checkbox
        } else if (id == "markets" && value.includes("select_all")) {
            //uniqueMarketCodesArray.push("select_all");
            inputValue =
                "checked" in (e?.detail || {}) ? e.detail.checked : uniqueMarketCodesArray; // Condition to handle if input type is checkbox
        } else {
            inputValue =
                "checked" in (e?.detail || {}) ? e.detail.checked : value; // Condition to handle if input type is checkbox
        }
        //console.log("inputValue -" + inputValue);
       
            setFormData((prevFormData) => ({...prevFormData, [id]: inputValue}));
        
        
    };

    const clearFormField = (e) => {
        console.log("Insider clear form field" + e);
    };

    useEffect(() => {
        if (workflowConfig.inputFields) {
            const initialFormData = {};
            workflowConfig.inputFields.forEach((field) => {
                initialFormData[field.fieldId] =
                    field.defaultValue !== undefined
                        ? getDefaultValue(field.defaultValue)
                        : ""; // Use defaultValue if specified
            });
            setWave(waveLabel)
            setMarket(marketCodes);
            setFormData(initialFormData);
        }
    }, [workflowConfig.inputFields]);

    // Render input fields dynamically

    const renderInputFields = () => {
        let waveValue = (wave && wave.length > 0) ? wave : waveLabel;
        let marketValue = (market && market.length > 0) ? market : marketCodes;
        if (workflowConfig.inputFields && workflowConfig.inputFields.length > 0) {
            console.log(waveValue);
            console.log(marketValue)
            return (
                
                <div className={styles["form-container"]}>
                    {workflowConfig.inputFields.map((field, index) => (
                        <div key={index} className={styles["row"]}>
                            <div className={styles["label"]}>
                                <WppTypography type="m-body">{field.fieldLabel}</WppTypography>
                                <WppTooltip className={styles["tooltip"]} text={field.tooltip}>
                                    <WppIconHelp></WppIconHelp>
                                </WppTooltip>
                            </div>
                            <div className={styles["field"]} style={{ position: 'relative' }}>
                                {field.fieldType === "boolean" ? (
                                    <WppCheckbox
                                        id={field.fieldId}
                                        checked={formData[field.fieldId] || false}
                                        onWppChange={handleInputChange}
                                    />
                                ) : field.fieldType === "dropdown" ? (
                                    <WppSelect
                                        type={field.fieldId === 'markets' ? "multiple" : "single"}
                                        id={field.fieldId}
                                        onWppChange={handleInputChange}
                                        value={
                                            formData[field.fieldId] ||
                                            getDefaultValue(field.defaultValue) ||
                                            ""
                                        }
                                        style={field.fieldId === 'markets' ? { '--wpp-input-select-dropdown-max-height': '25vh' } : {}}
                                    >
                                        {field.fieldId === 'year' ? (
                                            year.map((template, index) => (
                                                <WppListItem value={template.value} key={template.value}>
                                                    <p slot="label">{template.name}</p>
                                                </WppListItem>
                                            ))
                                        ) : field.fieldId === 'wave_label' || field.fieldId === 'wave' ? (
                                            waveValue.map((template, index) => (
                                                <WppListItem value={template.value} key={template.value}>
                                                    <p slot="label">{template.name}</p>
                                                </WppListItem>
                                            ))
                                        ) : field.fieldId === 'markets' ? (
                                            <>
                                                <WppListItem value="select_all" key="select_all">
                                                    <p slot="label">Select All</p>
                                                </WppListItem>
                                                <WppListItem value="clear_all" key="clear_all">
                                                    <p slot="label">Clear All</p>
                                                </WppListItem>

                                                {marketValue.map((template, index) => (
                                                    <WppListItem value={template.value} key={template.value}>
                                                        <p slot="label">{template.name}</p>
                                                    </WppListItem>
                                                ))}
                                            </>
                                        ) : (
                                            field.options.map((template, index) => (
                                                    <WppListItem key={index} value={template.value}>
                                                        <p slot="label">{template.label}</p>
                                                    </WppListItem>
                                                )
                                            )
                                        )}

                                    </WppSelect>
                                ) : (
                                    <WppInput
                                        id={field.fieldId}
                                        value={
                                            formData[field.fieldId] ? formData[field.fieldId]  : getDefaultValue(field.defaultValue) 
                                           }
                                        name={field.fieldId}
                                        onWppChange={handleInputChange}
                                    ></WppInput>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return null; // Return null if inputFields are not present or empty
        }
    };

    const triggerDag = async () => {
        const formDataToSend = {...formData};
        if (workflowConfig.inputFields && workflowConfig.inputFields.length > 0) {
            for (const field of workflowConfig.inputFields) {
                if (field.fieldType === "number") {
                    formDataToSend[field.fieldId] = parseInt(
                        formDataToSend[field.fieldId],
                        10
                    );
                } else if (field.fieldType === "array") {
                    formDataToSend[field.fieldId] = convertToJSONArray(
                        formDataToSend[field.fieldId]
                    );
                    // check this is temp param for export global dags
                }else if(field.fieldId === "markets" || field.fieldId === "market_ids" ){
                    formDataToSend[field.fieldId] = formDataToSend[field.fieldId].toString();
                }
                //Add more conditions for other field types as needed
            }
        }

        // Set the payload to null if formData is empty, otherwise stringify it
        const payload =
            Object.keys(formDataToSend).length > 0
                ? JSON.stringify(formDataToSend)
                : {};

        // Set the headers conditionally
        const headers = payload ? {"Content-Type": "application/json"} : {};

        try {
            setHasError(false);
            setIsLoading(true);
            // dagIdProperty: "ao_metadata_v2"
            if (workflowConfig.dagIdProperty === "ao_metadata_v2") {
                const metaPayload ={
                    survey: props.survey,
                }
                console.log(metaPayload);
                const triggerStatus = await AudienceOriginRequest.post(
                    `${EndPoints.TriggerDag}`,
                    metaPayload,
                    {headers}
                );
                setStatus(triggerStatus.data.state);
                setHasDagBeenTriggered(true);
                props.onDagSuccess();
            } else {
                const res = await AudienceOriginRequest.post(
                    EndPoints[workflowConfig.triggerDagPathProperty] +
                    "/" +
                    workflowConfig.dagIdProperty,
                    payload,
                    {headers}
                );

                setStatus(res.data.state);
                setHasDagBeenTriggered(true);
                props.onDagSuccess();
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message ?? error.message);
            setHasError(true);
        }
        setIsLoading(false);
    };

    if (isLoadings) {
        return <WppSpinner size="m"/>;
    }

    if (!hasDagBeenTriggered) {
        return (
            <div  style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                {props.survey &&
                    <div>
                    <WppTypography type="m-body">Survey Selected: </WppTypography>
                    <WppTypography type="m-midi">{props.survey}</WppTypography>
                    </div>
                }
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "150%",
                }}
            >
                {workflowConfig &&
                    workflowConfig.inputFields &&
                    workflowConfig.inputFields.length > 0 &&
                    renderInputFields()}
                {workflowConfig.defaultInfo && <div style={{marginBottom: 10}}>
                    <WppTypography type="m-strong">{workflowConfig.defaultInfo}</WppTypography>
                </div>}
                <WppButton style={{marginTop: 6, marginBottom: 10}} onClick={triggerDag}>
                    {workflowConfig.triggerDAGLabel}
                </WppButton>
                {hasError && <div style={{marginTop: 6}}>{errorMessage}</div>}
            </div>
            </div>
        );
    }

    // console.log(
    //     "workflowConfig.dagExecutionLog:" + workflowConfig.dagExecutionLog
    // );

    return (
        <WppTypography type="l-body">
            <ul>
                <li>Status of DAG: {status}</li>
                <li>
                    Slack Channel Name where notification will be sent
                    <ul>
                        <li>{AppConfig["slackChannelName"]}</li>
                    </ul>
                </li>

                <li>
                    Email Distribution List
                    <ul>
                        <li>{AppConfig["emailDistList"]}</li>
                    </ul>
                </li>

                <li>
                    Link for airflow UI where user can check logs
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href={workflowConfig.dagExecutionLog}
                            >
                                Click Here
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </WppTypography>
    );
};
