import React, {useState} from "react";
import ReactFlow, {MarkerType} from "reactflow";
import DisabledReactFlowNode from "./DisabledReactFlowNode";
import {SurveySummary, SurveySummaryStatus} from "../../../types";
import {
    WppButton,
    WppFloatingButton, WppIconQa, WppIconSparkle, WppSideModal,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import Chatbot from "../Common/Chatbot";

const initialEdges = [
    {
        id: "horizontal-e1-1",
        source: "horizontal-1",
        type: "smoothstep",
        target: "horizontal-2",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#58635b",
        },
        style: {
            strokeWidth: 1,
            stroke: "#58635b",
        },
    },
    {
        id: "horizontal-e1-2",
        source: "horizontal-1",
        type: "smoothstep",
        target: "horizontal-2",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#58635b",
        },
        style: {
            strokeWidth: 1,
            stroke: "#58635b",
        },
    },
    {
        id: "horizontal-e1-3",
        source: "horizontal-2",
        type: "smoothstep",
        target: "horizontal-3",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#58635b",
        },
        style: {
            strokeWidth: 1,
            stroke: "#58635b",
        },
    },
    {
        id: "horizontal-e3-4",
        source: "horizontal-3",
        type: "smoothstep",
        target: "horizontal-4",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#58635b",
        },
        style: {
            strokeWidth: 1,
            stroke: "#58635b",
        },
    },
    {
        id: "horizontal-e3-5",
        source: "horizontal-4",
        type: "smoothstep",
        target: "horizontal-5",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#58635b",
        },
        style: {
            strokeWidth: 1,
            stroke: "#58635b",
        },
    },
];

const proOptions = {hideAttribution: true};

const nodeTypes = {disabledNode: DisabledReactFlowNode};

const SURVEY_SUMMARY_WORKFLOW_ORDER = [
    SurveySummaryStatus.Meta,
    SurveySummaryStatus.Raw,
    SurveySummaryStatus.Ascription,
    SurveySummaryStatus.Net,
    SurveySummaryStatus.Weight,
];

export default function DataProcessingWorkflow({
                                                   data,
                                               }: {
    data: SurveySummary[];
}) {


    const [isQAModalOpen, setQAModalStatus] = useState(false)
    const handleOpenQAModal = () => setQAModalStatus(true)
    const handleCloseQAModal = () => setQAModalStatus(false)


    const getReactFlowFormattedSummary = (
        summary: SurveySummary,
        index: number,
        self: SurveySummary[]
    ) => {
        const defaultReactWorkflow = {
            id: `horizontal-${index + 1}`,
            sourcePosition: "right",
            type: "disabledNode",
            targetPosition: "left",
            data: {
                ...summary,
                label: summary.step,
                isInitial: false,
                isFinal: false,
                status: summary.status,
            },
            position: {x: index * 150, y: 80},
        };
        if (index === 0) {
            defaultReactWorkflow.data.isInitial = true;
        }
        if (index === self.length - 1) {
            defaultReactWorkflow.data.isFinal = true;
        }

        return defaultReactWorkflow;
    };

    const getReactFlowNodes = (summaries: SurveySummary[]) => {
        return SURVEY_SUMMARY_WORKFLOW_ORDER.map((summaryStatus, idx) => {
            const selectedSummary = summaries.find((s) => s.step === summaryStatus);
            if (selectedSummary) {
                return selectedSummary;
            }

            return {
                load_time: "",
                market_count: 0,
                status: "",
                step: summaryStatus,
                survey: "",
            };
        }).map(getReactFlowFormattedSummary);
    };

    const getFormattedSurveySummary = () => {
        const aggregatedSummary: Record<string, SurveySummary[]> = {};
        data.forEach((summary) => {
            if (!aggregatedSummary[summary.survey]) {
                aggregatedSummary[summary.survey] = [summary];
            } else {
                aggregatedSummary[summary.survey].push(summary);
            }
        });

        return Object.entries(aggregatedSummary)
            .map(([key, summaries]) => {
                return {
                    name: key,
                    workflow: getReactFlowNodes(summaries) as any,
                };
            })
            .sort((a, b) => b.name.localeCompare(a.name));
    };

    return (
        <div>
            <WppFloatingButton onClick={handleOpenQAModal} style={{ position: 'absolute', right: '4rem' }}>
                <WppIconQa />
            </WppFloatingButton>
            <WppSideModal
                open={isQAModalOpen}
                onWppSideModalClose={handleCloseQAModal}
                onWppSideModalOpen={handleOpenQAModal}
            >
                <div slot="header" style={{ display: 'flex', alignItems: 'center' }}>
                    <WppIconSparkle  style={{ marginRight: '0.5rem'}} ></WppIconSparkle>
                    <WppTypography type="l-strong">
                        QA Bot
                    </WppTypography></div>
                <div slot="body" >
                    <Chatbot isQAModalOpen={isQAModalOpen}></Chatbot>
                </div>
                    <div slot="actions">
                        <WppButton variant="primary" size="s" onClick={handleCloseQAModal}>Close</WppButton>
                    </div>
            </WppSideModal>

            <WppTypography type="2xl-heading" style={{marginLeft: "2rem"}}>
                Data Ingestion Panel
            </WppTypography>
            {getFormattedSurveySummary().map((summary, idx) => (
                <div
                    key={idx}
                    style={{
                        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                        margin: "2rem",
                        paddingTop: "1rem",
                        borderRadius: 6,
                    }}
                >
                    <div>
                        <WppTypography type="l-strong" style={{marginLeft: "4rem"}}>
                            Survey : {summary.name}
                        </WppTypography>
                    </div>
                    <div style={{height: "8rem"}}>
                        <ReactFlow
                            nodes={summary.workflow}
                            edges={initialEdges}
                            nodeTypes={nodeTypes}
                            fitView
                            nodesDraggable={true}
                            panOnDrag={false}
                            zoomOnScroll={false}
                            zoomOnPinch={false}
                            zoomOnDoubleClick={false}
                            nodesFocusable={false}
                            elementsSelectable={false}
                            nodesConnectable={false}
                            autoPanOnConnect={false}
                            autoPanOnNodeDrag={false}
                            proOptions={proOptions}
                        ></ReactFlow>
                    </div>
                </div>
            ))}
        </div>
    );
}
