import React, { useState } from "react";
import {
    WppInput,
    WppSelect,
    WppTypography,
    WppButton,
    WppListItem
} from "@platform-ui-kit/components-library-react";
import { AudienceOriginRequest, EndPoints } from "../../api/audience-origin";
import { STEP_CONTENT } from "../DataProcessing/MetadataIngestion/MetaDagConstants";

type Props = {
    testSuite: string;
    projectId: string;
    onDagSuccess: () => void;
};

export const TriggerDag = (props: Props) => {
    const [surveys, setSurveys] = useState("");
    const [useAscription, setUseAscription] = useState(null); 
    const [status, setStatus] = useState("-");
    const [dagId, setDagId] = useState("");
    const [dagRunId, setDagRunId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);

    const handleInputChange = async () => {
        if (!surveys || !props.testSuite || !props.projectId || useAscription === null) {
            setErrorMessage("Please fill out all required fields.");
            setHasError(true);
            return;
        }

        const datconfig = {
            surveys,
            test_suite: props.testSuite,
            project_id: props.projectId,
            use_ascription: useAscription
        };

        const dagName = "ao_qa";

        try {
            setHasError(false);
            setIsLoading(true);

            const triggerStatus = await AudienceOriginRequest.post(
                `${EndPoints.RawDataTriggerDAG}/${dagName}`,
                datconfig,
                { headers: { "Content-Type": "application/json" } }
            );

            setStatus(triggerStatus.data.state);
            setDagId(triggerStatus.data.dagId);
            setDagRunId(triggerStatus.data.dagRunId);
            props.onDagSuccess();
        } catch (error) {
            setErrorMessage(error.response?.data?.message ?? error.message);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAscriptionChange = (e) => {
        setUseAscription(e.target.value === "true"); 
    };

    const handleChange = (e) => {
        setSurveys(e.target.value);
    };

    const handleButtonClick = () => {
        handleInputChange();
    };

    return (
        <div>
            {status === "-" ? (
                <div>
                    <WppTypography>Survey name</WppTypography>
                    <WppInput value={surveys} onWppChange={handleChange} />
                   
                    <label>project_id</label>
                    <WppInput value={props.projectId}  />
                    <label>test_suite</label>
                    <WppInput value={props.testSuite} />
                    <label>use_ascription</label>
                    <WppSelect
                        onWppChange={handleAscriptionChange}
                        placeholder="use_ascription"
                        value={useAscription === null ? "" : useAscription ? "true" : "false"}
                    >
                        <WppListItem value="true">
                            <p slot="label">True</p>
                        </WppListItem>
                        <WppListItem value="false">
                            <p slot="label">False</p>
                        </WppListItem>
                    </WppSelect>
                    <WppButton
                        onClick={handleButtonClick}
                        style={{ marginTop: "1rem" }}
                    >
                        {isLoading ? "Triggering DAG..." : "Trigger DAG"}
                    </WppButton>
                    {hasError && (
                        <p style={{ color: "red", marginTop: "0.5rem" }}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            ) : (
                <WppTypography type="l-body">
                    <ul>
                        <li>Status of DAG: {status}</li>
                        <li>DAG Id: {dagId}</li>
                        <li>DAG Run Id: {dagRunId}</li>
                    
                    <li>
                    Link for airflow UI where user can check logs
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href={STEP_CONTENT.metadataAirflowLogs}                            >
                                Click Here
                            </a>
                        </li>
                    </ul>
                </li>
                </ul>
                </WppTypography>
            )}
        </div>
    );
};
